import React, { Fragment, useState } from 'react';
import ProductCarousel from '../ProductCarousel/ProductCarousel';
import QuickPeekModal from '../QuickPeekModal/QuickPeekModal';

import './ProductListContainer.scss';

const ProductListContainer = (props) => {
    const { title, item, apiCall } = props;

    const [ productCode, setProductCode ] = useState('');

    const quickPeek = (product_code) => {
        setProductCode(product_code);
    }

    const closeModal = () => {
        setProductCode('');
    }

    return (
        <Fragment>
            <QuickPeekModal show={productCode} onHide={() => closeModal()} />
            <div className="product-list-container">
                <div className="product-list-container-header">
                    <h3 className="product-list-name">
                        { title }
                    </h3>
                </div>
                <ProductCarousel data={item} onPeek={ (product_code) => quickPeek(product_code) } apiCall={(id) => apiCall(id)} />
            </div>
        </Fragment>
    );
}

export default ProductListContainer;