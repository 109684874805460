import * as actionTypes from './group-types';

const INITIAL_STATE = {
    groupList: [],
    isLoaded: false
}

const groupReducer = (state = INITIAL_STATE, action) => {
    let { groupList, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.GROUP_LIST:
            groupList = action.payload.data;
            isLoaded = groupList.length > 0;

            return {
                ...state,
                groupList,
                isLoaded
            };
        default: 
            return state;
    }
};

export default groupReducer;