import React, {Component} from 'react';
import NavigationContext from '../../Context/NavigationContext';
import './ProductPage.scss';

import ProductDetail from '../../Component/PagesComponent/ProductDetail/ProductDetail';
import ProductCatalog from '../../Component/PagesComponent/ProductCatalog/ProductCatalog';
import productAPI from '../../Data/Product';

class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCode: '',
            productId: 0,
            fetchType: 0
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        let state = this.state;
        let context = this.context;

        let { product_id, code } = context.params;

        state.productCode = code;
        state.productId = product_id;
        state.fetchType = productAPI.enum.fetchType.category;

        this.setState(state);
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        
        let state = this.state;
        let context = this.context;
        let componentUpdate = false;

        let { product_id, code } = context.params;

        if(state.productCode !== code) {
            state.productCode = code;
            componentUpdate = true;
        }

        if(state.productId !== product_id) {
            state.productId = product_id;
            componentUpdate = true;
        }

        if(state.fetchType !== productAPI.enum.fetchType.category) {
            state.fetchType = productAPI.enum.fetchType.category;
            componentUpdate = true;
        }

        if(componentUpdate) {
            this.setState(state);
        }
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className="product-page">
                <ProductDetail productCode={this.state.productCode} isModal={false} />
                <div className="product-page-related-products">
                    <ProductCatalog 
                        title={'Related Products'}
                        param={this.state.productId} 
                        fetchType={this.state.fetchType} 
                        excludeProduct={[this.state.productCode]}
                    />
                </div>
            </div>
        );
    }
}

export default ProductPage;