import React, { useState, useEffect, Fragment } from 'react';

import Init from '../../../Init/Init';
import './ProductCarousel.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import { Link } from 'react-router-dom';
import ProductCard from '../ProductCard/ProductCard';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Product Carousel';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const ProductCarousel = (props) => {
    /**
     * Initiate variables
     */
    const { FontAwesomeIcon } = Init;

    const { onPeek, data, apiCall } = props;
    const { id, storefront_name } = data;

    const [ products, setProducts ] = useState([]);

    /**
     * Load data through API
     */
    useEffect(() => {
        (async () => {
            const response = await apiCall(id);

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                setProducts(response.data);
            }
        })();
    }, []);

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "product-carousel-item-container";
    carouselConfig.itemClass = "product-carousel-item-holder";
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 9,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 990 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 990, min: 900 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        bigMobile: {
            breakpoint: { max: 900, min: 660 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 660, min: 350 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        potatoMobile: {
            breakpoint: { max: 450, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }

    }

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, slidesToShow, totalItems } } = rest;

        const slideBack = () => {
            GaTracker({
                action: 'Slide Back',
                label: `Storefront ${storefront_name} - Current Slide: ${currentSlide}; Slide To Show: ${slidesToShow}; Storefront Items: ${totalItems}`
            });

            previous();
        };

        const slideNext = () => {
            GaTracker({
                action: 'Slide Next',
                label: `Storefront ${storefront_name} - Current Slide: ${currentSlide}; Slide To Show: ${slidesToShow}; Storefront Items: ${totalItems}`
            });
            
            next();
        }

        return (
            <div className="navigation-container">
                <button className={"btn-custom-carousel-navigation left " + (currentSlide === 0 ? 'disabled' : '')} onClick={() => slideBack()}>
                    <FontAwesomeIcon
                        icon={['fas', 'chevron-left']}
                        className="icon left"
                    />
                </button>
                <button className={"btn-custom-carousel-navigation right "  + ( currentSlide + slidesToShow === totalItems ? 'disabled' : '' )} onClick={() => slideNext()}>
                    <FontAwesomeIcon 
                        icon={['fas', 'chevron-right']}
                        className="icon right"
                    />
                </button>
                <Link to={`/category?strfrnt=${id}`} className="btn-see-more">
                    See More
                </Link>
            </div>
        );
    };

    
    /**
     * Custom Functions
     */
    const quickLookModal = (productCode) => {
        onPeek(productCode);
    };


    /**
     * Render
     */
    return(
        <Fragment>
            {
                products.length > 0 ? (
                    <Carousel
                        arrows={false}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        swipeable={carouselConfig.swipeable}
                        draggable={carouselConfig.draggable}
                        showDots={carouselConfig.showDots}
                        responsive={carouselConfig.responsive}
                        ssr={carouselConfig.ssr} // means to render carousel on server-side.
                        infinite={carouselConfig.infinite}
                        autoPlay={carouselConfig.autoPlay}
                        autoPlaySpeed={carouselConfig.autoPlaySpeed}
                        keyBoardControl={carouselConfig.keyBoardControl}
                        transitionDuration={carouselConfig.transitionDuration}
                        removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                        deviceType={carouselConfig.deviceType}
                        containerClass={carouselConfig.containerClass}
                        dotListClass={carouselConfig.dotListClass}
                        itemClass={carouselConfig.itemClass}
                        centerMode={carouselConfig.centerMode}
                    >
                        {
                            products.map((value, key) => {
                                return (
                                    <ProductCard key={key} item={value} onPeek={ () => quickLookModal(value.product_code) } />
                                )
                            })
                        }
                    </Carousel>
                ) : ''
            }
        </Fragment>
    );
}

export default ProductCarousel;