import React, {Component} from 'react';
import './Homepage.scss';

import Header from '../../Component/PagesComponent/Header/Header';
import Storefronts from '../../Component/PagesComponent/Storefronts/Storefronts';
class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className="homepage">
                <Header />
                <Storefronts />
            </div>
        );
    }
}

export default Homepage;