import React, {Component} from 'react';

import './LoginPage.scss';

import Init from '../../Init/Init';
import UtilityFunction from '../../Util/Util';
import SecondaryButton from '../../Component/Utilities/SecondaryButton/SecondaryButton';

import NavigationContext from '../../Context/NavigationContext';



const { getGoogleOauthURL } = UtilityFunction;

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.config = {
            googleIcon: Init.config.icons.google,
            facebookIcon: Init.config.icons.facebook
        }
    }

    responseFacebook = (response) => {
        console.log(response);
    }

    static contextType = NavigationContext;

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    openFacebookOauth() {

    }

    openGoogleOauth() {
        window.location.href = getGoogleOauthURL();
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-container">
                    <h1>
                        Login to REDNAVI
                    </h1>
                    <SecondaryButton size="sm" customClass='login-item login-btn' onClick={() => this.openGoogleOauth()}>
                        <img src={this.config.googleIcon} alt="Google" />
                        Login with Google
                    </SecondaryButton>
                </div>
            </div>
        );
    }
}

export default LoginPage;