import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import transactionAPI from '../../../Data/Transaction';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import ContactInfo from '../../../Data/Contact';

import './OrderDetail.scss';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const { imageFolder } = Init.config;
const { transactionStatus } = Init.constants;
const { getFormattedDatetime, numberFormatting } = UtilityFunction;
const { bankAccount } = ContactInfo();

const OrderDetail = (props) => {
    const [ data, setData ] = useState({});

    const defaultImage = `${imageFolder}/placeholder.jpeg`;
    const navigate = useNavigate();

    useEffect( () => {
        if(props.transactionCode !== '') {
            ( async () => {
                const response = await transactionAPI.getOrderDetail(props.transactionCode);
    
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    response.data.transaction_status = transactionStatus.hasOwnProperty(response.data.transaction_status) ? transactionStatus[response.data.transaction_status] : 'Unknown';
                    response.data.subtotal = parseFloat(response.data.total_price);
                    response.data.total_price = response.data.subtotal + parseFloat(response.data.delivery_fee);
                    console.log(response.data);
                    setData(response.data);
                }
            } )();
        }
    }, [props.transactionCode]);

    const payNow = () => {
        if(data.payment_link !== '') {
            window.open(data.payment_link, '_blank');
        } else {
            navigate(`/payment-confirmation`, {
                replace: false,
                state: {
                    transactionCode:data.transaction_code
                }
            });
        }
    }

    return (
        <div className='order-detail'>
            {/* 
                Three parts:
                1. Header: Title, TransactionCode, Datetime, Status
                2. Products ordered
                3. Payment Info & Address
                4. Summary
            */}
            <div className="order-detail-header">
                <h4 className='header-title'>
                    Detail Pesanan
                </h4>
                <div className="header-info">
                    <div id="transaction-code" className="header-info-item">
                        <span className='header-info-label'>
                            No. Pesanan : 
                        </span>
                        <span className='header-info-value'>
                            { data.transaction_code }
                        </span>
                    </div>
                    <div id="transaction-time" className="header-info-item">
                        <span className='header-info-label'>
                            Waktu Pemesanan : 
                        </span>
                        <span className='header-info-value'>
                            { getFormattedDatetime(data.created_at, 'd M Y') }
                        </span>
                    </div>
                    <div id="transaction-status" className="header-info-item">
                        <span className='header-info-label'>
                            Status : 
                        </span>
                        <span className={`header-info-value ${data.transaction_status === 'Selesai' ? 'done' : ''}`}>
                            { data.transaction_status }
                        </span>
                    </div>
                </div>
            </div>
            <div className="order-detail-products">
                <div className="table-responsive">
                    <table className="table order-table">
                        <thead>
                            <tr>
                                <th className='product-table-header' colSpan={2}>Produk</th>
                                <th>Ukuran</th>
                                <th>Qty</th>
                                <th>Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.hasOwnProperty('transaction_products')
                                &&
                                data.transaction_products.length > 0 ? 
                                data.transaction_products.map( (item, index) => {
                                    let photoPath = item.photo_path !== null ? `${process.env.REACT_APP_API_PRODUCT_STORAGE}/${item.product_code}/${item.photo_path}` : defaultImage;

                                    return (
                                        <tr key={index}>
                                            <td className='product-table-photo'>
                                                <img src={photoPath} alt="" />
                                            </td>
                                            <td className='product-table-name'>
                                                {item.variant_name}
                                            </td>
                                            <td className='product-table-size'>
                                                {item.size_name}
                                            </td>
                                            <td className='product-table-qty'>
                                                {item.quantity}
                                            </td>
                                            <td>
                                                {numberFormatting(item.price)}
                                            </td>
                                        </tr>
                                    );
                                } )
                                : (
                                    <tr>
                                        <td colSpan={5}>
                                            Empty.
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>   
                </div>
            </div>
            <div className="order-detail-footer">
                <div className="order-detail-payment-info">
                    <div className="payment-info-item">
                        <div className="payment-info-item-header">
                            <h4 className='payment-info-item-title'>
                                Detil Pengiriman
                            </h4>
                        </div>
                        <div className="payment-info-item-content">
                            <div className="content-title">
                                { data.customer_fullname } { data.customer_phone_number }
                            </div>
                            <div className="content-text">
                                Alamat: { data.street_address }, { data.village_name }, { data.district_name }, { data.regency_name }, { data.province_name } { data.postal_code }
                            </div>
                            {
                                data.delivery_courier !== '' ? (
                                    <div className="context-text">
                                        Kurir: { data.delivery_courier } { data.delivery_service ? ` - ${data.delivery_service}` : '' }
                                    </div>
                                ) : ( '' )
                            }
                            {
                                data.delivery_id !== '' ? (
                                    <div className="context-text">
                                        Resi Pengiriman: { data.delivery_id }
                                    </div>
                                ) : ( '' )
                            }
                        </div>
                    </div>
                </div>
                <div className="order-detail-summary-container">
                    <h2 className='order-detail-summary-title'>
                        Summary
                    </h2>
                    <div className="order-detail-summary">
                        <table className='order-detail-summary-table'>
                            <tbody>
                                <tr>
                                    <td>Subtotal</td>
                                    <td align='right'>{ numberFormatting(data.subtotal) }</td>
                                </tr>
                                <tr>
                                    <td>Shipping Fee</td>
                                    <td align='right'>{ numberFormatting(data.delivery_fee) }</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className='total-row'>
                                    <td>TOTAL</td>
                                    <td align='right'>{ numberFormatting(data.total_price) }</td>
                                </tr>
                                {
                                    data.payment_link !== '' ? (
                                        ''
                                    ) : (
                                        <tr>
                                            <td colSpan={2}> Harap transfer ke: { bankAccount.bankAccountNo } ({ bankAccount.bank } a.n. { bankAccount.bankAccountName }) </td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td colSpan={2}>
                                        {
                                            data.transaction_status === transactionStatus[0] ? (
                                                <PrimaryButton size="md" customClass="payment-button" onClick={ () => payNow() }>
                                                    Pay Now!
                                                </PrimaryButton>
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetail;