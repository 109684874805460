import React, { Component, Fragment } from 'react';
import productAPI from '../../../Data/Product';

import ExploreNow from '../ExploreNow/ExploreNow';

import './Storefronts.scss';

import ProductListContainer from '../ProductListContainer/ProductListContainer';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

class Storefronts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            storefrontData: [],
            variantId: 0
        }
    }

    async componentDidMount() {
        let state = this.state;

        const response = await productAPI.storefrontList();
        let storefronts = [];

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            storefronts = response.data.map((item, index) => {
                item.exploreNow = index === 1;
                
                return item;
            });

            state.storefrontData = storefronts;

            this.setState(state);
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {
        return (
            <Fragment>
                <div className="storefront-wrapper">
                    {
                        this.state.storefrontData.length > 0 ?
                        (
                            this.state.storefrontData.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ProductListContainer title={item.storefront_name} item={item} apiCall={(id) => productAPI.storefrontProducts(id, {
                                            limit: 10
                                        })} />
                                        {
                                            item.exploreNow ? (
                                                <ExploreNow />
                                            ) : ''
                                        }
                                    </Fragment>
                                )
                            })
                        ) : (
                            <LoadingPage />
                        )
                    }
                </div>
            </Fragment>
        );
    }
}

export default Storefronts;