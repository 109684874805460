import React, { Component } from 'react';
import Init from '../../../Init/Init';
import './Footer.scss';

import ContactInfo from '../../../Data/Contact';
import UtilityFunction from '../../../Util/Util';

import { connect } from 'react-redux';

const { buildWaLink } = UtilityFunction;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerInfo: ContactInfo(),
            waLink: buildWaLink()
        };

        this.config = Init.config;
    }

    static getDerivedStateFromProps(props, state) {
        let identity = {};

        if(props.hasOwnProperty('identity') && typeof props.identity === 'object') {
            identity = props.identity;
        } 

        return {
            ...state,
            identity
        }
    }

    render() {
        let state = this.state;
        let config = this.config;
        let footerInfo = state.footerInfo;
        let FontAwesomeIcon = Init.FontAwesomeIcon;

        const { imageLogoAlt } = config;

        return (
            <footer id="contact" className={
                "footer-contact" 
                + 
                (this.props.hide ? ' d-none' : '')
            }>
                <div className="footer-contact-content">
                    <div className="content-first-column">
                        <div className="content-logo">
                            <img src={imageLogoAlt} alt="" />
                        </div>
                        <div className="content-tagline">
                            { footerInfo.tagline }
                        </div>
                    </div>
                    <div className="content-second-column">
                        {/* 
                            Profile
                            More Info
                            Address
                        */}
                        <div className="footer-menu content-profile">
                            <h4 className='footer-menu-title'>
                                Profil
                            </h4>
                            <div className="footer-menu-list">
                                <a href={this.state.identity.hasOwnProperty('id') ? "/profile/account" : "/login"} className="footer-menu-link">Detail Akun</a>
                                <a href={this.state.identity.hasOwnProperty('id') ? "/profile/address" : "/login"} className="footer-menu-link">Alamat Saya</a>
                                <a href={this.state.identity.hasOwnProperty('id') ? "/profile/order" : "/login"} className="footer-menu-link">Pesanan Saya</a>
                                <a target="_blank" rel="noreferrer" href="/order" className="footer-menu-link">Lacak Pesanan</a>
                                <a target="_blank" rel="noreferrer" href="/payment-confirmation" className='footer-menu-link'>Konfirmasi Pesanan</a>
                            </div>
                        </div>
                        <div className="footer-menu content-more-info">
                            <h4 className='footer-menu-title'>
                                More Info
                            </h4>
                            <div className="footer-menu-list">
                                <a target="_blank" rel="noreferrer" href="/terms-and-condition" className="footer-menu-link">Terms & Condition</a>
                                <a target="_blank" rel="noreferrer" href="/privacy-policy" className="footer-menu-link">Privacy Policy</a>
                                <a target="_blank" rel="noreferrer" href="/return-policy" className="footer-menu-link">Return Policy</a>
                                <a target="_blank" rel="noreferrer" href="/about-us" className="footer-menu-link">About Us</a>
                                <a target="_blank" rel="noreferrer" href={this.state.waLink} className="footer-menu-link">Contact Us</a>
                                <a target="_blank" rel="noreferrer" href="/faq" className="footer-menu-link">FAQ</a>
                            </div>
                        </div>
                        <div className="footer-menu content-address">
                            <h4 className='footer-menu-title'>
                                Alamat
                            </h4>
                            <div className="footer-menu-list">
                                <a target="_blank" rel="noreferrer" href={ footerInfo.maps.link } className="footer-menu-link">
                                    { footerInfo.address }
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="content-third-column">
                        {/* 
                            Connect With Us (Contact)
                        */}
                        <h4 className="content-contacts-title">
                            Connect With Us
                        </h4>
                        <div className="content-contact-items">
                            {
                                footerInfo.connect.map( (value, key) => {
                                    return value.show ? (
                                        <a target="_blank" rel="noreferrer" key={key} href={value.link} className="contact-item">
                                            {
                                                value.hasOwnProperty('faIcon') && value.faIcon ? (
                                                    <>
                                                        <FontAwesomeIcon className="contact-item-icon" icon={value.faIcon} /> { value.name }
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={value.customIcon} alt="" className='contact-item-custom-icon' /> { value.name }
                                                    </>
                                                )
                                            }
                                        </a>
                                    ) : ''
                                } )
                            }
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity
    }
}

export default connect(mapStateToProps)(Footer);