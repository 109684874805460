import React, { useEffect, useState } from 'react';

import bannerAPI from '../../../Data/Banner';

import Init from '../../../Init/Init';
import './Header.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Header';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const Header = (props) => {
    // let config = Init.config;
    // let iconPath = config.imageIcon;
    
    // Set state
    const [ banner, setBanner ] = useState([]);

    // Call API
    useEffect(() => {
        (async () => {
            const response = await bannerAPI.bannerList();

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let bannerPath = response.data.map( (value, key) => {
                    return {
                        banner_path: process.env.REACT_APP_API_BANNER_STORAGE + value.banner_path,
                        clickable_url: value.clickable_url
                    };
                } );

                setBanner(bannerPath);
            }
        })();
    }, []);

    /**
     * Carousels
     */
     let carouselConfig = Init.config.carousel;
     /**
      * Make sure to customize config before storing it to state 
      * if necessary.
      * 
      * E.g: 
      * let carouselConfig = Init.config.carousel
      * carouselConfig.swipeable = false?
      */
 
     carouselConfig.containerClass = "header-container";
     carouselConfig.itemClass = "header-item-holder";
     carouselConfig.showDots = true;
     carouselConfig.infinite = false;
     carouselConfig.responsive = {
         desktop: {
             breakpoint: { max: 3000, min: 1200 },
             items: 1,
             slidesToSlide: 1 // optional, default to 1.
         },
         tablet: {
             breakpoint: { max: 1200, min: 900 },
             items: 1,
             slidesToSlide: 1 // optional, default to 1.
         },
         mobileTablet: {
             breakpoint: { max: 900, min: 650 },
             items: 1,
             slidesToSlide: 1 // optional, default to 1.
         },
         mobile: {
             breakpoint: { max: 650, min: 0 },
             items: 1,
             slidesToSlide: 1 // optional, default to 1.
         }
     }

    return (
        <div className="header">
            <Carousel
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}
            >
                {
                    banner.length > 0 ? (
                        banner.map((v, k) => {
                            return v.clickable_url ? (
                                <a className="clickable-banner" key={k} href={v.clickable_url} onClick={ () => GaTracker({
                                    action: `Click Banner`,
                                    label: `Open Banner  ${k + 1}: ${v.clickable_url}`
                                }) }>
                                    <img className='header-item' key={k} src={v.banner_path} alt="" />
                                </a>
                            ) : (
                                <img className='header-item' key={k} src={v.banner_path} alt="" />
                            );
                        })
                    ) : (
                        <LoadingPage />
                    )
                }
            </Carousel>
        </div>
    );
}



export default Header;