import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
import { isMobile } from 'react-device-detect';

import ContactInfo from '../Data/Contact';

const UtilityFunction = {
    getFormattedDatetime: (dateString = null, dateFormat = 'Y-m-d H:i:s', use12Hour = false) => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    
        let d = null;
    
        if(dateString === null) {
            d = new Date();
        } else {
            d = new Date(dateString);
        }
    
        let hour = d.getHours();
        let amPm = '';
    
        if(use12Hour) {
            if(hour >= 12) {
                amPm = ' PM';
                hour -= 12;
            } else {
                amPm = ' AM';
            }
        }
    
        return dateFormat.replace('H', ('0' + hour).slice(-2))
                    .replace('i', ('0' + d.getMinutes()).slice(-2))
                    .replace('s', ('0' + d.getSeconds()).slice(-2)) 
                    .replace('Y', d.getFullYear())
                    .replace('y', d.getFullYear().toString().slice(-2))
                    .replace('m', ('0' + (d.getMonth() + 1)).slice(-2))
                    .replace('M', months[d.getMonth()])
                    .replace('d', ('0' + d.getDate()).slice(-2))
                    + amPm;

        // return `${d.getFullYear()}-${('0' + d.getMonth()).slice(-2)}-${('0' + d.getDate()).slice(-2)} ${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
    },
    getTimestamp: (dateString = null) => {
        let d = null;

        if(dateString === null) {
            d = new Date();
        } else {
            d = new Date(dateString);
        }

        return d.getTime();
    },
    base64encode: (string) => {
        let encodedString = Buffer.from(string);
        return encodedString.toString('base64');
    },
    base64decode: (string) => {
        let encodedString = Buffer.from(string, 'base64');
        return encodedString.toString();
    },
    // Addition in miliseconds
    dateAddition: (dateString = null, addition) => {
        let thisObj = UtilityFunction;
        return thisObj.getFormattedDatetime(thisObj.getTimestamp() + addition);
    },
    objToJson: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    capitalize: (string) => {
        let charByChar = string.split('');
        let firstLetter = charByChar.shift();
        firstLetter = firstLetter.toUpperCase();

        charByChar.unshift(firstLetter);

        return charByChar.join('');
    },
    responseFormat: (errorCode, message, data = {}) => {
        return {
            errorCode,
            message,
            data: data
        };
    },
    serializedURL: (obj) => {
        // Restructure object into a pair of [key,value]
        let entries = Object.entries(obj);

        // Serialize URL
        if(entries.length > 0) {
            return entries.map(e => e.join('=')).join('&');
        }

        return '';
    },
    sha256hash: (string) => {
        return CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
    },
    createRequestHash: (requestParam) => {
        let createdHash = null;
        let serializedParam = '';

        const { serializedURL, sha256hash } = UtilityFunction;

        const apiSecretKey = process.env.REACT_APP_API_SECRET_KEY;

        if(requestParam.hasOwnProperty('hash')) {
            delete requestParam.hash;
        } 

        // Sort all params
        requestParam = Object.keys(requestParam).sort().reduce(
            (obj, key) => { 
                obj[key] = requestParam[key]; 
                return obj;
            }, 
            {}
        );

        serializedParam = serializedURL(requestParam);

        // Prepend and append with api secret key, then hash with SHA 256 algorithm
        createdHash = sha256hash(apiSecretKey + serializedParam + apiSecretKey);

        return createdHash;
    },
    objectIsEmpty: (obj) => {
        return Object.keys(obj).length === 0;
    },
    numberFormatting: (str) => {
        return parseFloat(str).toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR'
        });
    },
    convertKeyToWords: (words) => {
        let splittedWords = words.split('-');
    
        for(let i=0;i<splittedWords.length;i++) {
            splittedWords[i] = splittedWords[i].charAt(0).toUpperCase() + splittedWords[i].slice(1);
        }
    
        return splittedWords.join(' ');
    },
    queryToObj: (queryString) => {
        const queryParams = queryString.replace('?', '').split('&');
        const queryObj = {};
        for(let i = 0;i < queryParams.length; i++) {
            let [ key, value ] = queryParams[i].split('=');

            queryObj[key] = decodeURI(value);
        }

        return queryObj;
    },
    getGoogleOauthURL: () => {
        const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

        const options = {
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            access_type: 'offline',
            response_type: 'code',
            prompt: 'consent',
            scope: [
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/userinfo.email'
            ].join(" ")
        }

        const qs = new URLSearchParams(options);

        return `${rootUrl}?${qs}`;
    },
    convertQueryToObj: (query) => {
        let params = query.replace('?', '').split('&');
        let arrReturn = {};

        for(let index in params) {
            let item = params[index];
            item = item.split('=');

            arrReturn[item[0]] = item[1];
        }

        return arrReturn;
    },
    buildWaLink: (isSharing = false, message = 'Halo Admin Rednavi, saya ingin menanyakan produk') => {
        const {
            phoneno
        } = ContactInfo();

        const waPhoneNumber = phoneno;

        if(!isSharing) {
            message = `phone=${waPhoneNumber}&text=${message}`;
        } else {
            message = `text=${message}`;
        }

        const waLink = isMobile ? `whatsapp://send?[queryParam]` : `https://web.whatsapp.com/send?[queryParam]`;

        return waLink.replace(
            '[queryParam]', 
            encodeURI(message)
        );
    }
}

export default UtilityFunction;