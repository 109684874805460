import { combineReducers } from 'redux';

import authReducer from './Auth/auth-reducer';
import shopReducer from './Shopping/shopping-reducer';
import brandReducer from './Brand/brand-reducer';
import groupReducer from './Group/group-reducer';
import marqueeReducer from './Marquee/marquee-reducer';
import FAQReducer from './FAQ/faq-reducer';

const rootReducer = combineReducers({
    shop: shopReducer,
    auth: authReducer,
    brand: brandReducer,
    group: groupReducer,
    marquee: marqueeReducer,
    FAQ: FAQReducer
});

export default rootReducer;