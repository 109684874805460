import React, {Component, Fragment, createRef} from 'react';
import {Navbar, Nav, NavLink} from 'react-bootstrap';
import './Navbar.scss';
import {Routes, BrowserRouter as Router, Route, useLocation, useParams, useSearchParams, useNavigate, Link} from "react-router-dom";

import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import Init from '../../../Init/Init';
import productAPI from '../../../Data/Product';

import NavigationContext from '../../../Context/NavigationContext';

import UtilityFunction from '../../../Util/Util';

import TopMenu from '../TopMenu/TopMenu';
import CustomMarquee from '../../Utilities/Marquee/Marquee';

import { connect } from 'react-redux';
import { getAuthenticatedUser } from '../../../Redux/Auth/auth-action';
import shoppingCartActions from '../../../Redux/Shopping/shopping-action';
import groupActions from '../../../Redux/Group/group-action';
import marqueeActions from '../../../Redux/Marquee/marquee-action';
import FAQActions from '../../../Redux/FAQ/faq-action';

const { loadCart } = shoppingCartActions;
const { getGroup } = groupActions;
const { getMarquee } = marqueeActions;
const { getFAQ } = FAQActions;

const mapStateToProps = (state) => {
    return {
        group: state.group.groupList,
        groupLoaded: state.group.isLoaded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIdentity: () => dispatch(getAuthenticatedUser()),
        loadCart: () => dispatch(loadCart()),
        // getCategories: () => dispatch(getCategories()),
        getGroup: () => dispatch(getGroup()),
        getMarquee: () => dispatch(getMarquee()),
        getFAQ: () => dispatch(getFAQ())
    }
};

const withParameters = (Component) => {
    function ComponentWithRouter(props) {
        const params = useParams();
        const [queryParams] = useSearchParams();
        const location = useLocation();

        const navigate = useNavigate(); 

        return (
            <NavigationContext.Provider value={{
                navigate: (targetPath, options = {
                    replace: true,
                    state: {}
                }) => {
                    navigate(targetPath, options);
                },
                params,
                queryParams,
                location
            }}>
                <Component 
                    {...props} 
                />
            </NavigationContext.Provider>
        )
    }

    return ComponentWithRouter
}
class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: window.location.pathname,
            group: {
                isLoaded: false,
                data: []
            },
            marquee: {
                isLoaded: false,
                data: []
            },
            searchText: createRef(),
            mobileScreen: window.innerWidth <= 768
        };
    }

    componentDidMount() {
        this.props.getIdentity();
        this.props.loadCart();
        this.props.getMarquee();
        this.props.getFAQ();
        
        if(!this.props.groupLoaded) {
            this.props.getGroup();
        }
        
        let thisComponent = this;
        let thisState = this.state;
        
        const queryParam = UtilityFunction.queryToObj(window.location.search);

        if(queryParam.hasOwnProperty('s')) {
            this.state.searchText.current.value = queryParam.s;
        }

        if(this.props.groupLoaded) {
            thisState.group = this.props.group;
            
            this.setState(thisState);
        }

        window.addEventListener('resize', () => {
            const screenWidth = window.innerWidth;
            const mobileScreen = screenWidth <= 768;

            if(thisComponent.state.mobileScreen !== mobileScreen) {
                thisComponent.setState({
                    ...thisComponent.state,
                    mobileScreen
                });
            }

        });   
    }

    componentDidUpdate() {
        const thisState = this.state;

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if(!thisState.group.isLoaded) {
            thisState.group.isLoaded = this.props.groupLoaded;
            thisState.group.data = this.props.group;
            
            this.setState({
                ...thisState
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    searchProduct(event) {
        // Enter Key Code = 13
        const searchKeyword = this.state.searchText.current.value;

        if(event.keyCode === 13 && searchKeyword !== '') {
            let searchPath = `/category?s=${searchKeyword}`;
            
            window.location.href = searchPath;
        }
    }

    chooseCategory(path) {
        let state = this.state;

        state.currentPath = path;

        this.setState(state);
    }

    toggleSubgroup(id, show) {
        const dropdown = document.getElementById(`navbar-hover-${id}`);

        if(dropdown !== null) {
            show ? dropdown.classList.add('show') : dropdown.classList.remove('show');
        }
    }

    render() {
        const { config, FontAwesomeIcon } = Init;
        let logoPath = config.imageLogo;
        
        const Topmenu = withParameters(TopMenu);

        return (
            <Fragment>
                <Router>
                    <Navbar className={`${ this.props.hide ? 'd-none' : '' }`} expand={false}>
                        <div className="navbar-first-row">
                            <div className={`
                                search-bar 
                                ${ this.state.mobileScreen ? 'd-none' : '' }
                            `}>
                                <FontAwesomeIcon icon={['fas', 'search']} className='search-icon' />
                                <input type="text" ref={this.state.searchText} onKeyDown={(event) => this.searchProduct(event)} className="search-box" placeholder='Search...'/>
                            </div>

                            <Navbar.Brand>
                                <Link to="/">
                                    <img className="nav-logo" src={logoPath} alt="Logo" />
                                </Link>
                            </Navbar.Brand>
                            
                            <Topmenu mobileScreen={this.state.mobileScreen} menu={this.state.group.data} />
                        </div>

                        <div className={`navbar-second-row ${this.state.mobileScreen ? 'd-none' : ''}`}>
                            {
                                this.state.group.isLoaded && 
                                this.state.group.data.map((v, k) => {
                                    const navPath = `/category/${v.id}`;
                                    let searchQuery = '';

                                    switch(v.group_type) {
                                        case productAPI.enum.fetchType.brand:
                                            searchQuery = `?b={id}`;
                                            break;
                                        case productAPI.enum.fetchType.category:
                                            searchQuery = `?p={id}`;
                                            break;
                                        case productAPI.enum.fetchType.search:
                                            searchQuery = `?s={name}`;
                                            break;
                                        default:
                                            break;
                                    }

                                    return (
                                        <Fragment key={k}>
                                            <Link 
                                                className={`brand-name ${ navPath === this.state.currentPath ? 'active' : '' }`} 
                                                onClick={() => this.chooseCategory(navPath)} 
                                                onMouseEnter={ () => this.toggleSubgroup(v.id, v.display_subgroup && true) }
                                                onMouseLeave={ () => this.toggleSubgroup(v.id, v.display_subgroup && false) }
                                                to={ v.group_name === 'Brands' ? '/category' : navPath}
                                            >
                                                { v.group_name }
                                            </Link>
                                            <div 
                                                id={ `navbar-hover-${ v.id }` } 
                                                className="navbar-hover"
                                                onMouseEnter={ () => this.toggleSubgroup(v.id, v.display_subgroup && true) }
                                                onMouseLeave={ () => this.toggleSubgroup(v.id, v.display_subgroup && false) }
                                            >
                                                <h3 className="group-name">
                                                    { v.group_name }
                                                </h3>
                                                <div className="group-items">
                                                    {
                                                        v.group_product.map( (item, index) => {
                                                            return (
                                                                <Link 
                                                                    key={index}
                                                                    className='group-item'
                                                                    to={`/category/${searchQuery.replace('{id}', item.id).replace('{name}', item.name)}`}
                                                                >
                                                                    { item.name }
                                                                </Link>
                                                            )
                                                        } )
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                })
                            }
                        </div>

                        <div className="navbar-third-row">
                            <CustomMarquee />
                        </div>
                    </Navbar>
                    {/* Render the first matched path */}
                    <Routes>
                        {
                            this.props.navList.map((route, i) => {
                                    const ComponentWithParameters = this.state.group.isLoaded ? withParameters(route.component) : LoadingPage;    
                                    
                                    return (
                                        <Route
                                            key={i}
                                            path={route.path}
                                            element={<ComponentWithParameters />}
                                        >
                                            {
                                                route.hasOwnProperty('route') && route.route.length > 0 ? (
                                                    route.route.map( (childRoute, index) => {
                                                        const ChildComponent = this.state.group.isLoaded ? withParameters(childRoute.component) : LoadingPage; 

                                                        return (
                                                            <Route
                                                                key={index}
                                                                path={childRoute.path}
                                                                element={<ChildComponent />}
                                                            />
                                                        )
                                                    } )
                                                ) : ''
                                            }
                                        </Route>
                                    )
                                } 
                            )
                        }
                    </Routes>
                </Router>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);