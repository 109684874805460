import Init from '../Init/Init';
import { isMobile } from 'react-device-detect';

const ContactInfo = () => {
    const { iconFolder, imageFolder } = Init.config;

    const contactData = {
        'maps'   : {
            link: 'https://www.google.com/maps/place/Rednavi+Sneakers+%26+Apparel/@-6.1246956,106.7082943,17z/data=!3m1!4b1!4m5!3m4!1s0x2e6a03c3d2f71dad:0x56c878dee7834b09!8m2!3d-6.1246956!4d106.710483',
            image: 'gmaps/rednavi.png'
        },
        'address': "Ruko Citra Garden City 6 West Lane blok H06A no 8, RT.4/RW.15, Tegal Alur, Kec. Kalideres, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11820",
        'phoneno': '+6281218918925',
        'email': 'rednavisneakers@gmail.com',
        'connect': [
            {
                name: "Rednavi Sneakers",
                link: '',
                show: true,
                faIcon: ['fab', 'whatsapp']
            },
            {
                name: "Rednavi Sneakers (Online)",
                link: '',
                show: false,
                faIcon: ['fab', 'whatsapp']
            },
            {
                name: "@rednavi_sneakers",
                link: "https://www.instagram.com/rednavi_sneakers/",
                show: true,
                faIcon: ['fab', 'instagram']
            },
            {
                name: "@rednavi_apparel",
                link: "https://www.instagram.com/rednavi_apparel/",
                show: true,
                faIcon: ['fab', 'instagram']
            },
            {
                name: "@rednavi_sneakers",
                link: "https://www.tiktok.com/@rednavi_sneakers?_t=8YikL354ok5&_r=1",
                show: true,
                faIcon: ['fab', 'tiktok']
            },
            {
                name: "Rednavi Sneakers",
                link: "https://www.tokopedia.com/rednavisneakers?source=universe&st=product",
                show: true,
                // faIcon: ['fab', 'tokopedia'],
                customIcon: `${iconFolder}tokopedia-icon.svg`
            },
            {
                name: "Rednavi Sneakers & Apparel",
                link: "https://shopee.co.id/rednavisneakers",
                show: true,
                // faIcon: ['fab', 'shopee'],
                customIcon: `${iconFolder}shopee-icon.svg`
            },
        ],
        'tagline': 'Your one-stop streetwear destination',
        'bankAccount': {
            "bank": "BCA",
            'bankAccountNo': '6470722221',
            'bankAccountName': 'Rednavi Retail Indonesia',
            'bankAccountQr': `${imageFolder}qris.jpg` 
        }
    };

    const waLink = isMobile ? `https://wa.me/${contactData.phoneno}/?[queryParam]` : `https://web.whatsapp.com/send?phone=${contactData.phoneno}&[queryParam]`;

    contactData.connect[0].link = waLink.replace(
        '[queryParam]', 
        `text=Halo Rednavi, saya ingin menanyakan produk`
    );
    
    contactData.connect[1].link = waLink.replace(
        '[queryParam]', 
        `text=Halo Rednavi, saya ingin menanyakan produk`
    );

    return contactData;
}

export default ContactInfo;