import React from 'react';
import Init from '../../Init/Init';

import './AboutUsPage.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

const { firstImage, secondImage, thirdImage, fourthImage } = Init.config.aboutUs;

const AboutUsPage = (props) => {
    const companyData = [
        {
            label: 'Contact Number:',
            value: `+62 21 5239 6084`,
            link: `tel:622152396084`
        },
        {
            label: 'Email Address:',
            value: `rednaviretailindonesia@gmail.com`,
            link: `mailto:rednaviretailindonesia@gmail.com`
        },
    ];

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "our-store-container";
    carouselConfig.itemClass = "our-store-holder";
    carouselConfig.showDots = true;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="about-us-page">
            {/* 
                1. Title
                2. Content
                3. Our Store
                    - Foto
                    or
                    - Video
            */}
            <div className="about-us-container">
                <h1 className="about-us-title">
                    About Rednavi
                </h1>
                <div className="about-us-content">
                    <p>
                        Since the Covid-19 pandemic that hit the whole world in 2019, <strong>REDNAVI</strong> began as an online sneakers store.
                    </p>
                    <p>
                        In April 2022, our 1st offline store opened in Jakarta. We started introducing a broad range of streetwear brands to the market. <strong>REDNAVI</strong> aims to become one of the best fashion retailers in Indonesia.
                    </p>
                    <p>
                        As <strong><em>One Stop Streetwear Destination</em></strong>, we curate the best brands and aim for continuous growth by following fast-changing trends.
                    </p>
                    <p>
                        In the future, we want to be a store that bridges global and local brands so that they can grow and collaborate.
                    </p>
                </div>
                <h2 className="about-us-title">
                    Our Store
                </h2>
                <div className="about-us-our-store">
                    <Carousel
                        swipeable={carouselConfig.swipeable}
                        draggable={carouselConfig.draggable}
                        showDots={carouselConfig.showDots}
                        responsive={carouselConfig.responsive}
                        ssr={carouselConfig.ssr} // means to render carousel on server-side.
                        infinite={carouselConfig.infinite}
                        autoPlay={carouselConfig.autoPlay}
                        autoPlaySpeed={carouselConfig.autoPlaySpeed}
                        keyBoardControl={carouselConfig.keyBoardControl}
                        transitionDuration={carouselConfig.transitionDuration}
                        removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                        deviceType={carouselConfig.deviceType}
                        containerClass={carouselConfig.containerClass}
                        dotListClass={carouselConfig.dotListClass}
                        itemClass={carouselConfig.itemClass}
                        centerMode={carouselConfig.centerMode}
                    >
                        <img src={firstImage} alt="First" />
                        <img src={secondImage} alt="Second" />
                        <img src={thirdImage} alt="Third" />
                        <img src={fourthImage} alt="Fourth" />
                    </Carousel>
                </div>
                <h3 className='about-us-title'>
                    Company Details
                </h3>
                <div className="about-us-company-profile">

                    <span className="company-name">
                        PT. Rednavi Retail Indonesia
                    </span>

                    <div className="company-content">
                        <div className="company-maps">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15868.178545554772!2d106.710483!3d-6.1246956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a03c3d2f71dad%3A0x56c878dee7834b09!2sRednavi%20Sneakers%20%26%20Apparel!5e0!3m2!1sen!2sid!4v1689915179592!5m2!1sen!2sid" className='company-gmaps' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div className="company-info">
                            {
                                companyData.map( (item, index) => {
                                    return (
                                        <div key={index} className="company-info-item" dangerouslySetInnerHTML={{__html: `${ item.label } <a href="${ item.link }">${ item.value }</a>`}}>
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;